import React, { useCallback, useState } from 'react';
import { Box, Grid, TextField, Typography } from '@mui/material';
import { EmailOutlined } from '@mui/icons-material';
import { styled } from '@mui/styles';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { ButtonDefault } from '../../../shared/components/ButtonDefault';
import { authStore } from '../auth-events';
import { useStore } from '@cobuildlab/react-simple-state';
import { authStepAction, authStoreAction } from '../auth-actions';
import { AuthStep } from '../auth-types';
import {
  useLoginMutation,
  useRecaptchaMutation,
} from '../../../shared/types/generated';
import { COOKIES_OPTIONS, USER_COOKIE } from '../../../shared/constans';
import { redirectToHome, updateQueryParams } from '../../../shared/utils';
import { useCookies } from 'react-cookie';
import { closeDialogAlert, openDialogAlert } from '../../alert/alert-actions';
import { Key } from '@mui/icons-material';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import validator from 'validator';
import { createProvider } from '../auth-utils';
import { PRIMARY_COLOR, PRIMARY_COLOR_MINIM, SECONDARY_COLOR } from '../../../shared/css/theme';
import { useLocation } from 'react-router-dom';
const StyledButton = styled(ButtonDefault)({
  borderRadius: '4px!important',
  backgroundColor: PRIMARY_COLOR,
  border: 'none!important',
  color: 'white!important',
  height: '38px !important',
  '&:disabled': {
    backgroundColor: SECONDARY_COLOR+'!important',
  },
  textTransform: 'none',
  fontWeight: 700,
});

const StyledIcon = styled(EmailOutlined)({
  color: PRIMARY_COLOR_MINIM,
  fontSize: '22px!important',
  paddingRight: '5px',
});
const StyledIconKey = styled(Key)({
  color: PRIMARY_COLOR_MINIM,
  fontSize: '22px!important',
  paddingRight: '5px',
});

const StyledVisibilityIcon = styled(VisibilityIcon)({
  cursor: 'pointer',
  color: PRIMARY_COLOR_MINIM,
  fontSize: '22px!important',
});

const StyledBox = styled(Box)({
  cursor: 'pointer',
});

export const AuthLoginForm: React.FC = () => {
  const location = useLocation();
  const returnTo = location?.state?.returnTo as string | undefined;
  const { email, isLogin } = useStore(authStore);
  const [loading, setLoading] = useState<boolean>(false);
  const [passwordType, setPasswordType] = useState<string | undefined>(
    undefined,
  );
  const { executeRecaptcha } = useGoogleReCaptcha();
  const [, setCookie] = useCookies([USER_COOKIE, 'analytics_uuid']);
  const [password, setPassword] = useState('');
  const handleError = useCallback(() => {
    setLoading(false);
    openDialogAlert('Tenemos problemas para verificar el usuario');
  }, []);

  const [authLogin] = useLoginMutation({
    onCompleted: ({ login }) => {
      setCookie(USER_COOKIE, login?.token, COOKIES_OPTIONS);
      createProvider('Correo electrónico');
      setLoading(false);
      redirectToHome(returnTo ?? '/');
    },
    onError: (e) => {
      setLoading(false);
      openDialogAlert(<>
        <Box>
          <Typography>{e.message}</Typography>
          <Box display={'flex'} justifyContent={'center'} alignItems={'center'}>
          <Typography 
            component={'div'}
            fontSize={14}
            paddingRight={1}
            style={
              {
              textDecoration:'underline', 
              cursor:'pointer'
              }
            } 
            color={'#E8600B'} 
            onClick={()=>{
              authStepAction(AuthStep.authRegister);
              closeDialogAlert();
              }}>
              ¿Crear cuenta?
          </Typography>
          <Typography>|</Typography>
          <Typography 
            component={'div'} 
            fontSize={14} 
            paddingLeft={1}
            style={
              {
              textDecoration:'underline', 
              cursor:'pointer' 
              }
            } 
            color={'#E8600B'} 
            onClick={()=>{
              authStepAction(AuthStep.authForgotPassword);
              closeDialogAlert();
              }}>
              Olvidé contraseña
          </Typography>
            
          </Box>
        </Box>
        </>);
    },
  });

  const [authRecaptcha] = useRecaptchaMutation({
    onCompleted: () => {
      authLogin({
        variables: {
          data:{
            email,
            password,
          }
        },
      });
    },
    onError: () => handleError(),
  });

  const checkUser = useCallback(async () => {
    if (!executeRecaptcha) {
      return handleError();
    }
    const token = await executeRecaptcha('auth_email');

    authRecaptcha({
      variables: {
        token,
        email: email,
      },
    });
  }, [email, executeRecaptcha, authRecaptcha, handleError]);

  const handleVisibility = (): void => {
    if (!passwordType) setPasswordType('text');
    else setPasswordType(undefined);
  };

  const onSubmit = (): void => {
    setLoading(true);
    // TODO: easy data

    checkUser();
  };

  return (
    <Grid container rowSpacing={2}>
      
      <Grid marginTop={0.5} color={'black'} item xs={12}>
        <TextField
          variant="outlined"
          placeholder={'Dirección de correo electrónico'}
          value={email}
          name="email"
          type="text"
          fullWidth
          sx={{
            '& fieldset': { border: 'none' },
          }}
          InputProps={{
            startAdornment: <StyledIcon />,
            style: {
              background: '#00000011',
              fontSize: '16px',
              height: '38px',
            },
          }}
          InputLabelProps={{
            style: { fontSize: '16px' },
          }}
          onChange={(event) => authStoreAction(event.target.value, isLogin)}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          placeholder={'Contraseña'}
          name="password"
          type={passwordType ?? 'password'}
          value={password}
          fullWidth
          sx={{
            '& fieldset': { border: 'none' },
          }}
          variant="outlined"
          color="primary"
          FormHelperTextProps={{ style: { fontSize: '16px' } }}
          InputLabelProps={{
            style: { fontSize: '16px' },
          }}
          InputProps={{
            endAdornment: (
              <StyledVisibilityIcon onClick={() => handleVisibility()} />
            ),
            startAdornment: <StyledIconKey />,
            style: {
              fontSize: '16px',
              background: '#00000011',
              height: '38px',
            },
          }}
          onChange={(event) => {
            setPassword(event.target.value);
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <Box textAlign="center">
          <StyledButton
            isLoading={loading}
            variant="contained"
            size="large"
            color="primary"
            fullWidth
            disabled={!validator.isEmail(email as string) || password === ''}
            onClick={() => onSubmit()}
            style={{ width: '100%', textTransform: 'none', fontWeight: 700 }}>
            Iniciar sesión
          </StyledButton>
        </Box>
      </Grid>
      <Grid item xs={12} mb={0}>
        <StyledBox
          color={PRIMARY_COLOR}
          textAlign="center"
          style={{
            color: 'black',
            textDecoration: 'underline',
            fontSize: '14px',
          }}
          onClick={() => authStepAction(AuthStep.authForgotPassword)}>
          Olvidé mi contraseña
        </StyledBox>
      </Grid>
    </Grid>
  );
};