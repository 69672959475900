import { APP_ENV } from './constans';
import { getCurrentUrl, getIsIFrame, getPostUrl, getRedirectUrl } from '../modules/auth/auth-utils';

export const redirectToHome = (route: string): void => {
  const isIframe = getIsIFrame();
  const redirectTo = getRedirectUrl();
  const postTo = getPostUrl();

  if (redirectTo || postTo) {
    window.location.href = redirectTo || postTo;
  } else if (isIframe) {
    window.parent.location = getCurrentUrl() as string;
  } else {
    window.location.href =
      APP_ENV !== 'local' ? 'https://vanguardia.com' : route;
  }
};
 export const updateQueryParams:(key: string, value: string) => void = (key:string, value:string) => {
  const params = new URLSearchParams(window.location.search);
  params.set(key, value);
  window.history.pushState({}, '', `${window.location.pathname}?${params}`);
  window.location.reload();
};

export const getReadingTime = (textHtml: string): number => {
  const container = document.createElement('div');
  container.innerHTML = textHtml;

  return Math.round((container.textContent as string).split(' ').length / 250);
};