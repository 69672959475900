import { createStoreAction } from '@cobuildlab/react-simple-state';
import { dialogAlertStore } from './alert-events';
import { ReactNode } from 'react';

export const openDialogAlert = createStoreAction(
  dialogAlertStore,
  (prev, message: string| ReactNode, extraAction?: () => void) => ({
    ...prev,
    message,
    extraAction,
    isOpen: true,
  }),
);

export const closeDialogAlert = createStoreAction(dialogAlertStore, (prev) => ({
  ...prev,
  isOpen: false,
}));
