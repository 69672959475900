import React, { ChangeEvent, useState } from 'react';
import { Grid, TextField, Box, Typography } from '@mui/material';
import { styled } from '@mui/styles';
import { ButtonDefault } from '../../../shared/components/ButtonDefault';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { authStore } from '../auth-events';
import validator from 'validator';
import { useStore } from '@cobuildlab/react-simple-state';
//import { getPostUrl } from '../auth-utils';
import { useCookies } from 'react-cookie';
import { COOKIES_OPTIONS, USER_COOKIE } from '../../../shared/constans';
import { redirectToHome } from '../../../shared/utils';
import { useSignupMutation } from '../../../shared/types/generated';
import { AuthStep, SignupArgs } from '../auth-types';
import { closeDialogAlert, openDialogAlert } from '../../alert/alert-actions';
import { EmailOutlined, Key } from '@mui/icons-material';
import { Person } from '@mui/icons-material';
import {PRIMARY_COLOR, PRIMARY_COLOR_MINIM, SECONDARY_COLOR } from '../../../shared/css/theme';
import { useLocation } from 'react-router-dom';
import { getCurrentUrl } from '../auth-utils';
import { authStepAction } from '../auth-actions';

const StyledIcon = styled(EmailOutlined)({
  color: PRIMARY_COLOR_MINIM,
  fontSize: '22px!important',
  paddingRight: '5px',
});
const StyledIconAvatar = styled(Person)({
  color: PRIMARY_COLOR_MINIM,
  fontSize: '22px!important',
  paddingRight: '5px',
});
const StyledIconKey = styled(Key)({
  color: PRIMARY_COLOR_MINIM,
  fontSize: '22px!important',
  paddingRight: '5px',
});
const StyledButton = styled(ButtonDefault)({
  borderRadius: '4px!important',
  backgroundColor: PRIMARY_COLOR,
  border: 'none!important',
  color: 'white!important',
  height: '38px !important',
  '&:disabled': {
    backgroundColor: SECONDARY_COLOR+'!important',
  },
  textTransform: 'none',
  fontWeight: 700,
});


const StyledVisibilityIcon = styled(VisibilityIcon)({
  cursor: 'pointer',
  color: PRIMARY_COLOR_MINIM,
  fontSize: '22px!important',
});

export const AuthRegisterForm: React.FC = () => {
  const location = useLocation();
  const returnTo = location?.state?.returnTo as string | undefined;
  const { email } = useStore(authStore);
  const [, setCookie] = useCookies([USER_COOKIE]);
  
  const [registerData, setRegisterData] = useState<SignupArgs>({
    email: email ?? '',
    name: '',
    password: '',
  });
  const [passwordType, setPasswordType] = useState<string | undefined>(
    undefined,
  );
  const handleSubmit = (): boolean => {
    let checkData = true;
    Object.keys(registerData).forEach((key) => {
      if (!registerData[key as keyof SignupArgs] && checkData)
        checkData = false;
    });

    return checkData;
  };

  const onChange = (
    event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ): void => {
    setRegisterData({
      ...registerData,
      [event.target.name]: event.target.value,
    });
  };

  const [signUp, { loading }] = useSignupMutation({
    onCompleted: ({ signup }) => {
      setCookie(USER_COOKIE, signup?.token, COOKIES_OPTIONS);
      redirectToHome(returnTo ?? '/');
    },
    onError: (e) =>{
      if(e.message==='El usuario ya se encuentra registrado'){
        openDialogAlert(<>
        <Box>
          <Typography>{e.message}</Typography>
          <Box display={'flex'} justifyContent={'center'} alignItems={'center'}>
          <Typography 
            component={'div'}
            fontSize={14}
            paddingRight={1}
            style={
              {
              textDecoration:'underline', 
              cursor:'pointer'
              }
            } 
            color={'#E8600B'} 
            onClick={()=>{
              authStepAction(AuthStep.authLogin);
              closeDialogAlert();
              }}>
              ¿Iniciar sesión?
          </Typography>
          <Typography>|</Typography>
          <Typography 
            component={'div'} 
            fontSize={14} 
            paddingLeft={1}
            style={
              {
              textDecoration:'underline', 
              cursor:'pointer'
              }
            } 
            color={'#E8600B'} 
            onClick={()=>{
              authStepAction(AuthStep.authForgotPassword);
              closeDialogAlert();
              }}>
              Olvidé contraseña
          </Typography>
            
          </Box>
        </Box>
        </>);
      }
      
    }
  });

  const handleVisibility = (): void => {
    if (!passwordType) setPasswordType('text');
    else setPasswordType(undefined);
  };

  return (
    <Grid container spacing={2}>
      <Grid marginTop={0.5} item xs={12}>
        <TextField
          variant="outlined"
          value={registerData?.email}
          name="email"
          placeholder={'Dirección de correo electrónico'}
          type="text"
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
          sx={{
            '& fieldset': { border: 'none' },
          }}
          InputProps={{
            startAdornment: <StyledIcon />,
            style: {
              fontSize: '16px',
              background: '#00000011',
              height: '38px',
            },
          }}
          onChange={(event) => onChange(event)}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          fullWidth
          placeholder={'Nombre'}
          name="name"
          type="text"
          size="small"
          variant="outlined"
          InputLabelProps={{
            shrink: true,
          }}
          sx={{
            '& fieldset': { border: 'none' },
          }}
          InputProps={{
            startAdornment: <StyledIconAvatar />,
            style: {
              fontSize: '16px',
              background: '#00000011',
              height: '38px',
            },
          }}
          value={registerData?.name}
          onChange={(event) => onChange(event)}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          placeholder={'Contraseña'}
          name="password"
          size="small"
          type={passwordType ?? 'password'}
          value={registerData?.password}
          fullWidth
          variant="outlined"
          InputLabelProps={{
            shrink: true,
          }}
          sx={{
            '& fieldset': { border: 'none' },
          }}
          InputProps={{
            endAdornment: (
              <StyledVisibilityIcon onClick={() => handleVisibility()} />
            ),
            startAdornment: <StyledIconKey />,
            style: {
              fontSize: '16px',
              background: '#00000011',
              height: '38px',
            },
          }}
          onChange={(event) => onChange(event)}
        />
      </Grid>
      <Grid item xs={12}>
        <Typography textAlign={'center'} color={'black'} fontSize={14}>
          La contraseña debe tener al menos 8 caracteres, incluyendo mayúsculas,
          minúsculas, números y caracteres especiales.
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Box textAlign="center" mt={2}>
          <StyledButton
            variant="contained"
            size="large"
            color="primary"
            fullWidth
            disabled={
              !handleSubmit() ||
              !validator.isEmail(registerData.email as string)
            }
            onClick={() => {
              signUp({
                variables: {
                  data:{
                    email: registerData.email,
                    name: registerData.name,
                    password: registerData.password,
                    url: getCurrentUrl()
                  }
                },
              });
            }}
            isLoading={loading}
            style={{ width: '100%', textTransform: 'none', fontWeight: 700 }}>
            Regístrate
          </StyledButton>
        </Box>
      </Grid>
    </Grid>
  );
};